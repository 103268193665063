import { reactive } from 'vue';
import { toLocal } from './storages';

const state = reactive({
    token: null,
    users: [],
    user: null,
    lastLoggedUser: null,
    forward: null,
    themeMode: 'light',
    device: 'desktop',

    //FILTERS

    filters: {
        region: null,
        subregion: null,
        operation: null,
        'practice-area': null,
        'business-line': null,
        'business-unit': null,
        director: null,
        type: null,
        rank: null,
        'key-account-leader': null,
        date: null,
        calendar: 'Año',
    },

    dateFrom: null,
    dateTo: null,

    selects: {
        subregion: null,
        operation: null,
        'practice-area': null,
        'business-line': null,
        'business-unit': null,
        director: null,
        type: null,
        rank: null,
        'key-account-leader': null,
        date: null,
    },

    businessFilter: null,
    regionFilter: null,
    subRegionFilter: null,
    operationFilter: null,
    areaFilter: null,
    currencyFilter: null,

    //NEW FILTERS
    typeFilter: null,
    typeFilterName: 'global',

    rankFilter: null,
    rankFilterName: null,

    keyAccountLearFilter: null,
    keyAccountLearFilterName: null,

    businessFilterName: null,
    regionFilterName: null,
    subRegionFilterName: null,
    operationFilterName: null,
    areaFilterName: null,
    currencyFilterName: null,

    //FILTRO FECHA
    financialPeriod: {
        // start_date: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        // end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
        start_date: null,
        end_date: null,
    },

    marketingPeriod: {
        // start_date: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        // end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
        start_date: null,
        end_date: null,
    },
});

toLocal(state, {
    // namespace: 'sesame-next',
    attrs: [
        'token',
        'lastLoggedUser',
        'forward',
        'themeMode',
        'businessFilter',
        'financialPeriod',
        'marketingPeriod',
        'user',
    ],
});

const actions = {
    setDateFrom(v) {
        state.dateFrom = v;
    },

    setDateTo(v) {
        state.dateTo = v;
    },
    getFilter(child) {
        return state.filters[child];
    },
    setFilter(child, value) {
        state.filters[child] = value;
    },

    getSelect(child) {
        return state.selects[child];
    },
    setSelect(child, value) {
        state.selects[child] = value;
    },

    setState(key, val) {
        state[key] = val;
    },
    getState(key) {
        return state[key];
    },

    setPeriod(period) {
        state.period = period;
    },

    getDevice() {
        return state.device;
    },
    setDevice(v) {
        state.device = v;
    },
    setToken(token) {
        state.token = token;
    },
    setUsers(users) {
        state.users = users;
        state.user = users[0];
    },
    setUser(user) {
        state.user = user;

        if (user?.id) {
            state.lastLoggedUser = user.id;
        }
    },
    resetAuth() {
        state.token = null;
        state.user = null;
        state.users = [];
        state.lastLoggedUser = null;
        state.forward = null;
    },
    setForward(route) {
        state.forward = route;
    },
    toggleTheme(theme = null) {
        if (theme !== null) {
            state.themeMode = theme;
        } else {
            state.themeMode = state.themeMode === 'dark' ? 'light' : 'dark';
        }
        setMode();
    },
    setMode() {
        setMode();
    },
    setFinancialPeriod(financialPeriod) {
        state.financialPeriod = financialPeriod;
    },
    setMarketingPeriod(marketingPeriod) {
        state.marketingPeriod = marketingPeriod;
    },
    resetFilters() {
        state.filters = {
            region: null,
            subregion: null,
            operation: null,
            'practice-area': null,
            'business-line': null,
            'business-unit': null,
            director: null,
            type: null,
            rank: null,
            'key-account-leader': null,
            date: null,
            calendar: 'Año',
        };

        state.selects = {
            subregion: null,
            operation: null,
            'practice-area': null,
            'business-line': null,
            'business-unit': null,
            director: null,
            type: null,
            rank: null,
            'key-account-leader': null,
            date: null,
        };

        //state.businessFilter = null;
        //state.regionFilter = null;
        //state.subRegionFilter = null;
        //state.operationFilter = null;
        //state.areaFilter = null;
        //state.currencyFilter = null;
        //
        //state.businessFilterName = null;
        //state.regionFilterName = null;
        //state.subRegionFilterName = null;
        //state.operationFilterName = null;
        //state.areaFilterName = null;
        //state.currencyFilterName = null;
        //
        //state.financialPeriod = {
        //    start_date: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        //    end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
        //};
        //state.marketingPeriod = {
        //    start_date: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        //    end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
        //};
        //
        //state.typeFilter = null;
        //state.rankFilter = null;
        //state.keyAccountLearFilter = null;
    },
};

export function setMode() {
    let htmlElement = document.getElementsByTagName('html')[0];
    if (state.themeMode === 'dark') {
        htmlElement.classList.remove('light-mode');
        htmlElement.classList.add('dark-mode');
    } else {
        htmlElement.classList.remove('dark-mode');
        htmlElement.classList.add('light-mode');
    }
}

export { state, actions };
