export default {
    transparent: 'transparent',
    // current: 'currentColor',
    black: '#000000',
    white: '#ffffff',
    body: '#121822',
    module: '#202837',
    box: '#283143',
    piece: '#1B2331',
    // font: {
    //     DEFAULT: '#79849A',
    //     white: '#ffffff',
    //     light: '#B4BCCE',
    //     dark: '#475462',
    // },
    font: '#79849A',
    'font-white': '#ffffff',
    'font-light': '#B4BCCE',
    'font-dark': '#475462',
    'gray-aaa': '#AAAAAA',
    aux: '#4F65E0',
    danger: '#FF5D61',
    warning: '#F8B97B',
    success: '#6ECAA4',
    shadow: '#000000',
    brand: '#F23E58',
    secondary: '#8986B1',
    grey: '#D8D8D8',
    'gray-light': '#f6f6fb',
    'gray-mid': '#f7f7fc',
    'gray-dark': '#F4F4FA',
    'dark-blue': '#0E2135',
};
