import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// FREE ICONS

import {
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faXmark as fasXmark,
    faBriefcase as fasBriefcase,
    faRightFromBracket as fasRightFromBracket,
    faEarthEurope as fasEarthEurope,
    faEye as fasEye,
    faRotate as fasRotate,
    faPersonWalkingArrowRight as fasPersonWalkingArrowRight,
    faArrowRightArrowLeft as fasArrowRightArrowLeft,
    faMars as fasMars,
    faVenus as fasVenus,
    faArrowUpLong as fasArrowUpLong,
    faArrowDownLong as fasArrowDownLong,
} from '@fortawesome/free-solid-svg-icons';

import {
    faUser as farUser,
    faEnvelopeOpen as farEnvelopeOpen,
    faThumbsDown as farThumbsDown,
} from '@fortawesome/free-regular-svg-icons';

// PRO ICONS

//import { faClockRotateLeft as farClockRotateLeft } from '@fortawesome/pro-solid-svg-icons';

import {
    faClockRotateLeft as farClockRotateLeft,
    faImageSlash as farImageSlash,
    faVideo as farVideo,
} from '@fortawesome/pro-regular-svg-icons';

import { faGlobe as falGlobe, faCalendar as falCalendar } from '@fortawesome/pro-light-svg-icons';

//import { faRightFromBracket as fasRightFromBracket } from '@fortawesome/sharp-solid-svg-icons';

import { faPoo as faslPoo } from '@fortawesome/sharp-light-svg-icons';

const faConfig = () => {
    library.add(farUser);
    library.add(farEnvelopeOpen);
    library.add(farThumbsDown);

    library.add(fasChevronLeft);
    library.add(fasChevronRight);
    library.add(fasXmark);
    library.add(fasBriefcase);
    library.add(fasEarthEurope);
    library.add(fasEye);
    library.add(fasRotate);
    library.add(fasPersonWalkingArrowRight);
    library.add(fasArrowRightArrowLeft);
    library.add(fasMars);
    library.add(fasVenus);
    library.add(fasArrowUpLong);
    library.add(fasArrowDownLong);

    library.add(fasRightFromBracket);

    library.add(falGlobe);
    library.add(falCalendar);

    library.add(farClockRotateLeft);
    library.add(farImageSlash);
    library.add(farVideo);

    /*library.add(fasText);

    library.add(faslPoo);
    library.add(fasCircleO);

    library.add(fassHouse);
    library.add(fassArrowRightFromBracket);
    library.add(fassArrowRight);
    library.add(fassCircle);

    library.add(farArrowRight);
    library.add(farArrowDown);
    library.add(farHouse);
    library.add(farCartShopping);
    library.add(farReceipt);
    library.add(falUsers);
    library.add(farMoneyBillWave);

    library.add(farCalendarRange);
    library.add(farCalendarDay);
    library.add(farCalendarCircleExclamation);
    library.add(farXmark);
    library.add(farCircle);
    library.add(farTablet);

    library.add(faCircleO);
    library.add(fasPig);
    library.add(fasCow);
    library.add(fasBird);
    library.add(fasSausage);
    library.add(fasCircleEllipsis);
    library.add(fasCircleCheeseSwiss);
    library.add(fasBaguette);
    library.add(fasVial);*/
};

export { faConfig, FontAwesomeIcon };
