import { actions } from './store';
actions.setMode();
import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

import '@/assets/css/main.css';

// import 'vue2-animate/dist/vue2-animate.min.css';

//INSTALL CONFIGURED HIGHCHARTS
import { HighchartsVue, Highcharts } from './mainPlugins/highcharts.js';
app.use(HighchartsVue, { highcharts: Highcharts });

//INSTALL ROUTER
import router from './router';
app.use(router);

import { getLabel } from '@/utils/labels.js';
app.use(getLabel);

import { faConfig, FontAwesomeIcon } from './mainPlugins/fontawesome';
faConfig();
app.component('font-awesome-icon', FontAwesomeIcon);

// import { setI18n } from './mainPlugins/i18n/';
// app.use(setI18n());

//FILTERS
// import { numberFormat } from './mainPlugins/numberFormat';
// app.use(numberFormat);
// import { datesFunctions } from './mainPlugins/datesFn';
// app.use(datesFunctions);

app.mount('#app');
