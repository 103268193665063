export const getLabel = slug => {
    const labels = {
        amount: 'amount',
        average_weight: 'average weight',
        average_weighting: 'average weighting',
        business_unit: 'business unit',
        by_categories: 'by categories',
        closed_provisionally_won: 'closed + provisionally won',
        corporate_affairs_generation: 'corporate affairs generation',
        currency: 'currency',
        current_month_sales: 'current month sales',
        current_quarter_sales: 'current quarter sales',
        desk: 'desk',
        desktop: 'desktop',
        directors: 'directors',
        diversity: 'diversity',
        email_marketing: 'email marketing',
        employee_turnover_rate: 'employee turnover rate',
        eur: 'EUR',
        everyone: 'everyone',
        female: 'female',
        global_data: 'global data',
        hc_vs_budget: 'hc vs. budget',
        hires: 'hires',
        in_budget: 'in budget',
        include_provisional: 'include provisional',
        income_kcls: 'Income KCLs',
        international_mobility: 'International mobility',
        kcls_by_nb: 'KCLs by NB',
        key_client_list: 'Key Client List',
        top_client_list: 'top client list',
        last_year: 'last year',
        last_month_sales: 'last month sales',
        lead: 'lead',
        leads_to_prospects: 'leads to prospects',
        local_mobility: 'local mobility',
        loss_and_non_estimated: 'loss and non estimated',
        male: 'male',
        management_team: 'management team',
        marketing_generation: 'marketing generation',
        media_impact: 'media impact',
        missing: 'missing',
        mobility: 'mobility',
        mails_sent: 'mails sent',
        nb_incorporated: 'NB incorporated',
        nb_ponderation: 'NB Ponderation',
        nb_temporary: 'NB Temporary',
        nb_won: 'NB Won',
        nb_won_offered: 'NB won + offered',
        new: 'new',
        new_business_by_opportunity_type: 'new business by opportunity type',
        new_business_by_type_of_opportunities: 'new business by type of opportunities',
        new_business_incorporated: 'new business incorporated',
        new_business_ponderation: 'new business ponderation',
        new_creation: 'new creation',
        total_processes: 'total processes',
        newsletters_sent: 'newsletters sent',
        open_opportunities: 'open opportunities',
        open_rate: 'open rate',
        operation: 'operation',
        opportunities_by_origin: 'opportunities by origin',
        over_last_period: 'Over last period',
        monthly_evolution: 'Monthly Evolution',
        pipeline: 'pipeline',
        previous_month: 'previous month',
        previous_period: 'previous period',
        promotions: 'promotions',
        over_previous_month: 'Over previous month',
        prospect: 'prospect',
        prospects_to_sales: 'prospects to sales',
        rank_kcls_inc: 'rank. kcls inc.',
        leader_ranking_inc: 'leader ranking inc',
        leader_ranking_nb: 'leader ranking nb',
        leader_ranking_list: 'leader ranking list',
        ranking_key_clients: 'ranking key clients',
        top_client_leaders_ranking: 'top client leaders ranking',
        top_client_leaders_ranking_short: 'TC leaders ranking',
        top_clients_ranking: 'top clients ranking',
        top_clients_ranking_short: 'TC ranking',
        top_leaders_ranking_short: 'TC leaders rank.',
        leaders_ranking: 'leaders ranking',
        recruiting_process: 'recruiting process',
        region: 'region',
        renewal: 'renewal',
        revenue_volume: 'revenue volume',
        rotation: 'rotation',
        sale: 'sale',
        sales: 'sales',
        sales_closed: 'sales closed',
        sales_closed_won: 'sales closed won',
        sales_development_vs_objectives: 'sales development vs objectives',
        sales_current_vs_previous_year: 'sales current vs previous year',
        sales_funnel: 'sales funnel',
        sales_generator: 'sales generator',
        sales_generators: 'sales generators',
        sales_vs_targets_evolution: 'sales vs targets evolution',
        select: 'select',
        select_capability: 'select capability',
        select_director: 'select director',
        select_region: 'select region',
        select_subregion: 'select subregion',
        select_operation: 'select operation',
        select_type: 'select type',
        select_rank: 'select rank',
        select_key_account_leader: 'select key account leader',
        key_account_leader: 'key account leader',
        select_business_unit: 'select business unit',
        select_practice_area: 'select practice area unit',
        practice_area: 'practice area',
        service_type_analysis: 'service type analysis',
        social_network: 'social network',
        starting_funds: 'Starting funds',
        starting_revenue: 'Starting revenue',
        substitution: 'substitution',
        substitutions: 'substitutions',
        talent: 'talent',
        talent_detail: 'talent detail',
        hires_detail: 'hires detail',
        target: 'target',
        temporary_sales_vs_objectives: 'temporary sales vs objectives',
        time_to_hire: 'Time to hire',
        top_clients: 'top clients',
        total_generation: 'total generation',
        total_incorporations: "total incorporation's",
        total_offered: 'total offered',
        total_promotions: 'total promotions',
        total_sales: 'total sales',
        total_turnover: 'total turnover',
        turndown_rate: 'turndown rate',
        turnover: 'turnover',
        undesired: 'undesired',
        vs_internal: 'vs. internal',
        vs_total_admissions: 'vs. total admissions',
        web: 'web',
        with_respect_to_the_previous_period: 'With respect to the previous period',
        won: 'won',
        north_america: 'north america',
        latin_america: 'latin america',
        last_3_publications: 'last 3 publications',
        usuarios_web_llyc: 'usuarios web LLYC',
        traffic_sources: 'traffic sources',
        latam_north: 'LATAM north',
        latam_south: 'LATAM south',
        top_stories: 'top stories',
        tier_1: 'tier 1',
        web_llyc_new_users: 'web LLYC new users',
        likes_by_post: 'likes by post',
        average_telmi_users_per_month: 'Average Telmi users per month',
        telmi_users: 'telmi users',
        month_to_month: 'month to month',
        year_to_year: 'year to year',
        paid_media: 'paid media',
        no_filters: 'No filters',
        no_selected_filters: 'No selected filters',
        none: 'None',
        year_evolution: 'Year evolution',
        operational_income: 'operational income',
        personal_expenses: 'personal expenses',
        other_expenses: 'other expenses',
        corporate_expenses: 'corporate expenses',
    };

    slug.toString().trim();

    return labels[slug.toString().toLowerCase()] ?? slug.toString().replace('_', 'x');
};
